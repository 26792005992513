var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"PVu0U2D9Vu9L26MwtE4sW"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { allowSampling } from 'configs/sentry'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const SAMPLE_RATE = 0.05

Sentry.init({
  enabled: false,
  environment: process.env.NEXT_PUBLIC_ENV,
  release: `${process.env.NEXT_PUBLIC_ENV}@${
    process.env.NEXT_PUBLIC_SHA || ''
  }`,
  beforeSend(event, hint) {
    const originalException = hint.originalException
    if (
      typeof originalException === 'string' &&
      originalException.indexOf('this error can be safely ignored') >= 0
    ) {
      return null
    }
    return event
  },
  dsn:
    SENTRY_DSN ||
    'https://a5467526c0e04a9e9ceefd6f9775fd33@o1279780.ingest.sentry.io/6511491',
  // Adjust this value in production, or use tracesSampler for greater control
  sampleRate: SAMPLE_RATE,
  tracesSampler: (samplingContext) => {
    if (process.env.NEXT_PUBLIC_SENTRY_NEW_SAMPLER === 'true') {
      return allowSampling(samplingContext) ? 0.0002 : false
    } else {
      if (process.env.NEXT_PUBLIC_ENV !== 'production') {
        return false
      }

      if (
        !samplingContext.location.href.includes(
          process.env.NEXT_PUBLIC_BASE_URL
        )
      ) {
        return false
      }

      return 0.0002
    }
  },

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})

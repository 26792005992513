import { format as formatFns, parse } from 'date-fns'
import id from 'date-fns/locale/id'
import { format, register } from 'timeago.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
require('dayjs/locale/id')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('id')

import { roundWithPrecision } from 'utils/number'

import type { TDate } from 'timeago.js'

export const locale = 'id'

register(
  locale,
  (_number: number, index: number, totalSec: number): [string, string] => {
    const translations = [
      ['baru saja', 'sebentar'],
      ['%s detik lalu', 'dalam %s detik'],
      ['1 menit lalu', 'dalam 1 menit'],
      ['%s menit lalu', 'dalam %s menit'],
      ['1 jam lalu', 'dalam 1 jam'],
      ['%s jam lalu', 'dalam %s jam'],
      ['1 hari lalu', 'dalam 1 hari'],
      ['%s hari lalu', 'dalam %s hari'],
      ['1 minggu lalu', 'dalam 1 minggu'],
      ['%s minggu lalu', 'dalam %s minggu'],
      ['1 bulan lalu', 'dalam 1 bulan'],
      ['%s bulan lalu', 'dalam %s bulan'],
      ['1 tahun lalu', 'dalam 1 tahun'],
      ['%s tahun lalu', 'dalam %s tahun'],
    ]
    const translation = translations[index] as [string, string]
    if (translation[0].includes('minggu')) {
      const dayInSec = 86400
      const days = Math.ceil(totalSec / dayInSec)
      return [`${days} hari lalu`, `dalam ${days} hari`]
    }
    return translation
  }
)

export const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]

export function humanizeDate(timestamp: string): string {
  const dateObj = new Date(timestamp)
  const date = dateObj.getDate()
  const month = dateObj.getMonth()
  const year = dateObj.getFullYear()
  const hour =
    dateObj.getHours() > 9 ? dateObj.getHours() : '0' + dateObj.getHours()
  const minute =
    dateObj.getMinutes() > 9 ? dateObj.getMinutes() : '0' + dateObj.getMinutes()

  return `${date} ${months[month]} ${year}, ${hour}:${minute}`
}

export function youtubeDurationToSeconds(duration: string): number {
  // https://developers.google.com/youtube/v3/docs/videos#contentDetails.duration

  let hours = 0
  let minutes = 0
  let seconds = 0

  duration = duration.replace('PT', '')

  if (duration.indexOf('H') > -1) {
    const hoursSplit = duration.split('H')
    hours = parseInt(hoursSplit[0])
    duration = hoursSplit[1]
  }

  if (duration.indexOf('M') > -1) {
    const minutesSplit = duration.split('M')
    minutes = parseInt(minutesSplit[0])
    duration = minutesSplit[1]
  }

  if (duration.indexOf('S') > -1) {
    const secondsSplit = duration.split('S')
    seconds = parseInt(secondsSplit[0])
  }

  return hours * 3600 + minutes * 60 + seconds
}

export function youtubeDurationToHms(duration: string): string {
  const durationInSeconds = youtubeDurationToSeconds(duration)
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = Math.floor((durationInSeconds % 3600) % 60)

  const hoursDisplay = hours > 0 ? String(hours).padStart(2, '0') + ':' : ''
  const minutesDisplay = String(minutes).padStart(2, '0') + ':'
  const secondsDisplay = String(seconds).padStart(2, '0')

  return hoursDisplay + minutesDisplay + secondsDisplay
}

export function timeAgo(datetime: TDate): string {
  return format(datetime, locale)
}

export function minuteToHour(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} menit`
  }
  return `${roundWithPrecision(minutes / 60, 1)} jam`
}

export function isoStringToDateInputTypeFormat(isoDateString: string) {
  const date = new Date(isoDateString)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let dt = date.getDate()
  let sday = dt.toString()
  let smonth = month.toString()

  if (dt < 10) {
    sday = '0' + dt.toString()
  }
  if (month < 10) {
    smonth = '0' + month.toString()
  }
  return year.toString() + '-' + smonth + '-' + sday
}

export function getTimestampForGTag() {
  let date = new Date()
  date = new Date(date.getTime() - 3000000)
  return (
    date.getFullYear().toString() +
    '-' +
    ((date.getMonth() + 1).toString().length == 2
      ? (date.getMonth() + 1).toString()
      : '0' + (date.getMonth() + 1).toString()) +
    '-' +
    (date.getDate().toString().length == 2
      ? date.getDate().toString()
      : '0' + date.getDate().toString()) +
    ' ' +
    (date.getHours().toString().length == 2
      ? date.getHours().toString()
      : '0' + date.getHours().toString()) +
    ':' +
    (((date.getMinutes() / 5) * 5).toString().length == 2
      ? ((date.getMinutes() / 5) * 5).toString()
      : '0' + ((date.getMinutes() / 5) * 5).toString()) +
    ':00'
  )
}

export function formatDate(
  datetime: Date | string,
  formatString = 'dd MMMM yyyy'
): string {
  return formatFns(new Date(datetime), formatString, { locale: id })
}

interface IncrementDateOptions {
  days?: number
  formatWithoutTime?: boolean
}

export function incrementHumanizedDate(
  date: string,
  options?: IncrementDateOptions
) {
  const { days = 0, formatWithoutTime = true } = options || {}

  const getDateHumanizeDate: any = (date: string) => {
    if (formatWithoutTime) {
      return humanizeDate(date).split(',')[0]
    }
    return humanizeDate(date)
  }

  if (days >= 0) {
    const dateObj = new Date(date)
    dateObj.setDate(dateObj.getDate() + days)
    return getDateHumanizeDate(dateObj)
  }

  return getDateHumanizeDate(date)
}

/**
 * Checks if a given date is later than another date.
 * @param e - The date to compare.
 * @param f - The date to compare against. Defaults to the current date and time.
 * @returns True if `e` is later than `f`, false otherwise.
 */
export const isLaterThan = (e: Date, f: Date = new Date()) => {
  return e.getTime() > f.getTime()
}

/**
 * Checks if a given date is later than or equal to another date.
 * @param e - The date to compare against.
 * @param f - The date to compare with. Defaults to the current date if not provided.
 * @returns True if the second date is later than or equal to the first date, false otherwise.
 */
export const isLaterThanOrEqualTo = (e: Date, f: Date = new Date()) => {
  return f.getTime() >= e.getTime()
}

/**
 * This function only check its format, not its validity, will parse any string to Date object.
 * Example: February 30th is considered valid, but parsed into March 1st, which is not valid.
 *
 * @deprecated Use isValidDate instead
 */
export function isDateValid(dateStr: string) {
  return !isNaN(new Date(dateStr).getTime())
}

/**
 * Checks if a given date string is valid.
 *
 * @param {string} dateStr - The date string to be validated.
 * @returns {boolean} - Returns true if the date string is valid and not February 30th or 32nd, otherwise false.
 */
export function isValidDate(dateStr: string): boolean {
  const parsed = parse(dateStr, 'yyyy-MM-dd', new Date())
  return !isNaN(parsed.getTime())
}

/**
 * Checks if date's date is between the given start and end dates.
 *
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns A boolean indicating whether today's date is between the start and end dates.
 */
export function isTodayBetweenDates(startDate: Date, endDate: Date): boolean {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return false
  }

  const today = new Date()
  return startDate <= today && today <= endDate
}

/**
 * Checks if a given date is between two other dates, inclusive.
 *
 * @param date - The date to check.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns `true` if the date is between the start and end dates, inclusive; otherwise, `false`.
 */
export function isDateBetweenDates(
  date: Date,
  startDate: Date,
  endDate: Date
): boolean {
  if (
    !(date instanceof Date) ||
    !(startDate instanceof Date) ||
    !(endDate instanceof Date)
  ) {
    return false
  }

  return startDate <= date && date <= endDate
}

/**
 * Adds the specified number of days to the given date.
 *
 * @param {string} date - The date in string format (YYYY-MM-DD).
 * @param {number} days - The number of days to add.
 * @returns {string} The resulting date after adding the specified number of days, in string format (YYYY-MM-DD).
 *
 * @example
 * // Adding 3 days to the date '2022-01-01'
 * const result = addDaysToDate('2022-01-01', 3);
 * console.log(result); // Output: '2022-01-04'
 */
export function addDaysToDate(date: string, days: number): string {
  const dateObj = new Date(date)
  dateObj.setDate(dateObj.getDate() + days)
  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

/**
 * Adds the specified number of months to the given date.
 *
 * @param {string} date - The date in string format (YYYY-MM-DD).
 * @param {number} months - The number of months to add.
 * @returns {string} The resulting date after adding the specified number of months, in string format (YYYY-MM-DD).
 *
 * @example
 * // Adding 3 months to the date '2022-01-01'
 * const result = addMonthsToDate('2022-01-01', 3);
 * console.log(result); // Output: '2022-04-01'
 */
export function addMonthsToDate(date: string, months: number): string {
  const dateObj = new Date(date)
  const day = dateObj.getDate()

  dateObj.setMonth(dateObj.getMonth() + months)

  // Handle leap year and month-end cases
  if (dateObj.getDate() < day) {
    dateObj.setDate(0)
  }

  return formatDate(dateObj, 'yyyy-MM-dd')
}

/**
 * Retrieves the current timezone and UTC offset.
 *
 * @returns An object containing the guessed timezone and the UTC offset.
 * @property {string} timezone - The guessed timezone.
 * @property {string} utcDiff - The UTC offset in the format '+HH:mm' or '-HH:mm'.
 */
export const getTimezone = () => {
  return { timezone: dayjs.tz.guess(), utcDiff: dayjs().format('Z') }
}

/**
 * Retrieves the locale timezone abbreviation based on the UTC offset.
 *
 * @returns {string} The locale timezone abbreviation:
 * - 'WIB' for UTC+07:00
 * - 'WITA' for UTC+08:00
 * - 'WIT' for UTC+09:00
 * - The full timezone name if the UTC offset does not match any of the above, eg. Australia/Sydney
 */
export const getLocaleTimezone = () => {
  const { utcDiff, timezone } = getTimezone()
  if (utcDiff === '+07:00') return 'WIB'
  if (utcDiff === '+08:00') return 'WITA'
  if (utcDiff === '+09:00') return 'WIT'
  return timezone
}

/**
 * Formats a given date and time with the specified format and appends the local timezone.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Date | string} params.dateTime - The date and time to format.
 * @param {string} [params.format='dd MMMM yyyy, HH:mm'] - The format string to use for formatting the date and time.
 * @param {string} [params.timezoneSeparator=' '] - The separator to use between the formatted date-time and the timezone.
 *
 * @returns {string} The formatted date-time string with the local timezone appended. Local timezone is in the format 'WIB', 'WITA', 'WIT', or the full timezone name eg. 'Australia/Sydney'.
 */
export const formatDateTimeWithTimezone = ({
  dateTime,
  format = 'dd MMMM yyyy, HH:mm',
  timezoneSeparator = ' ',
}: {
  dateTime: Date | string
  format?: string
  timezoneSeparator?: string
}) => {
  return [formatDate(dateTime, format), getLocaleTimezone()].join(
    timezoneSeparator
  )
}

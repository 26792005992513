export interface UnprotectedRouteConfig {
  pathname: string
  query?: string
}

export type UnprotectedRoute = UnprotectedRouteConfig | string

export const UNPROTECTED_ROUTES: Array<UnprotectedRoute> = [
  '/',
  '/404',
  '/login',
  '/bukti-karya',
  '/bukti-karya/video/[id]',
  '/bukti-karya/video/upload-success',
  '/bukti-karya/[bkCategory]/[bkId]',
  '/profil/[id]',
  '/profil/[id]/perangkat-ajar/[category]',
  '/sertifikat/cek-keabsahan/[signatureKey]',
  {
    pathname: '/bukti-karya/video/select',
    query: 'token',
  },
  '/pdf-viewer',
  '/kurikulum-merdeka',
  '/tentang-kurikulum-merdeka',
  '/forwarder',
  '/info-terkini/[id]',
  '/dokumen',
  '/dokumen/kategori/[id]',
  '/dokumen/daftar/[id]',
  '/dokumen/[id]',
  ...(process.env.NEXT_PUBLIC_LMS_CONNECT_REDIRECT !== 'true'
    ? []
    : ['/lms-connect']),
  '/pengajuan-hapus-akun',
  '/pengelolaan-kinerja-landing',
]

export const ROUTE_PATH_NAMES = {
  assessment: 'Asesmen Murid',
  'perangkat-ajar': 'Perangkat Ajar',
  'pelatihan-mandiri': 'Pelatihan Mandiri',
  'bukti-karya': 'Bukti Karya',
  komunitas: 'Komunitas',
  'video-inspirasi': 'Video Inspirasi',
  home: 'Beranda',
}

export const EXCLUDE_GSI_CLIENT_PATHS = ['/']

export const EXCLUDE_INITIAL_API_FETCH_PATHS = [
  '/',
  '/logout',
  '/dokumen',
  '/dokumen/kategori/[id]',
  '/dokumen/daftar/[id]',
  '/dokumen/[id]',
]

import React from 'react'
import { useRouter } from 'next/router'

import AndroidHandler from 'utils/androidHandler'
import * as AppAuth from './useAppAuth'
import * as WebAuth from './useWebAuth'
import { isBrowser, isPathProtected } from './utils'
import { APP_LOGIN_STORAGE_KEY } from 'configs/auth'
import { useTogglesStore } from 'stores/toggles'

import type { ProtectedRoutesComponentProps } from './types'
import type { Session } from 'types/auth'

export function useAuth() {
  const androidHandler = AndroidHandler()
  const Context = androidHandler.enabled
    ? AppAuth.AuthContext
    : WebAuth.AuthContext
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export function useAuthProvider(immediate: boolean) {
  const androidHandler = AndroidHandler()

  /* eslint-disable react-hooks/rules-of-hooks */
  // Android handler is not reactive, therefore it should be safe here
  // and instead we get a beautiful polymorphism!
  return androidHandler.enabled
    ? AppAuth.useAuthProvider(immediate)
    : WebAuth.useAuthProvider(immediate)
  /* eslint-enable react-hooks/rules-of-hooks */
}

export function AuthProvider(props) {
  const androidHandler = AndroidHandler()
  return androidHandler.enabled ? (
    <AppAuth.AuthProvider {...props} />
  ) : (
    <WebAuth.AuthProvider {...props} />
  )
}

export function ProtectedRoutes({
  router,
  children,
}: ProtectedRoutesComponentProps) {
  const { session } = useAuth()
  const androidHandler = AndroidHandler()

  // Toggle for Pengelolaan Kinerja Landing Page
  const isPengelolaanKinerjaLandingPageEnabled = useTogglesStore(
    (state) => state.toggles?.['kinerja-2025-landing-page-enabled']
  )

  if (isBrowser() && session === null && isPathProtected(router)) {
    if (androidHandler.enabled) {
      androidHandler.requestLogin()
    } else if (router.pathname === '/logout') {
      router.replace('/')
    } else if (
      // NOTE:
      // Specifically for /pengelolaan-kinerja and any subsequent pages,
      // a non-logged-in user will be redirected to its Landing Page
      // i.e. /pengelolaan-kinerja-landing
      isPengelolaanKinerjaLandingPageEnabled &&
      router.pathname.includes('/pengelolaan-kinerja') && // Any Penglelolaan Kinerja pages
      router.pathname !== '/pengelolaan-kinerja-landing' // Prevent infinite redirection
    ) {
      router.replace({
        pathname: '/pengelolaan-kinerja/landing',
        query: {
          from: window.location.pathname + window.location.search,
        },
      })
    } else {
      router.replace({
        pathname: '/login',
        query: {
          from: window.location.pathname + window.location.search,
        },
      })
    }
    return null
  }

  return children
}

export function setAuthToAPI(
  url: string,
  { accessToken, tokenType, refreshToken, expiryToken }: Session
): string {
  const urlObj = new URL(url)
  urlObj.searchParams.set('accessToken', accessToken)
  urlObj.searchParams.set('tokenType', tokenType)
  urlObj.searchParams.set('refreshToken', refreshToken)
  urlObj.searchParams.set('expiryToken', expiryToken)
  return urlObj.toString()
}

export function getSession(
  storageKey: string = APP_LOGIN_STORAGE_KEY
): Session | null {
  try {
    return JSON.parse(window.localStorage.getItem(storageKey))
  } catch {
    return null
  }
}

export function useTriggerOrLogin() {
  const { session } = useAuth()
  const androidHandler = AndroidHandler()
  const router = useRouter()

  const run = React.useCallback(
    (callback: (..._args: any[]) => void, ...params) => {
      if (session) {
        callback.apply(null, params)
      } else {
        if (androidHandler.enabled) {
          androidHandler.requestLogin()
        } else {
          router.push({
            pathname: '/login',
            query: {
              from: router.asPath,
            },
          })
        }
      }
    },
    [session, router, androidHandler]
  )

  return {
    run,
  }
}
